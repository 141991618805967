/* import Vue from 'vue';
const EventBus = new Vue();
export default EventBus; */

import { getCurrentInstance } from 'vue';

export default function useEmitter() {
  const internalInstance = getCurrentInstance();
  const emitter = internalInstance.appContext.config.globalProperties.emitter;

  return emitter;
}
